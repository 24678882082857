import {
  ADD_EMPLOYEE,
  GET_EMPLOYEE_LIST,
  GET_EMPLOYEE_BY_ID,
  UPDATE_EMPLOYEE,
  // GET_EMPLOYEE_DYNAMIC_REPORT_LIST,
  // RESET_EMPLOYEE_DYNAMIC_REPORT,
  DELETE_EMPLOYEE,
  RESET_EMPLOYEE,
  // GET_EMPLOYEE_REPORT_LIST,
  GET_EMPLOYEE_DOCS,
  GET_EMPLOYEE_DOCS_DETAILS,
  EMPLOYEE_SEARCH,
  EMPLOYEE_COUNTRY_LIST,
  EMPLOYEE_BIRTH_PASSPORT_GRADUATION_COUNTRY_LIST,
  GET_ALL_EMPLOYEE_REQUESTS,
  GET_ALL_REQUEST_TYPES,
  SET_CURRENT_REQUEST,
  GET_ALL_ACTIVE_EMPLOYEES,
  GET_EMPLOYEE_TOTALS,
  GET_ALL_REQUESTS_OPTIONS,
  GET_EMPLOYEE_FINANCIAL_REPORTS_LIST,
  SET_CURRENT_EMPLOYEE,
  GET_ALL_EMPLOYEE_VACATION,
  GET_ALL_EMPLOYEE_VACATION_LIST,
  GET_ALL_EMPLOYEE_VACATION_LIST_FOR_SET,
  GET_EMPLOYEES_WITH_LIBRARY_SUMMARY,
  GET_EMPLOYEE_CLASSES,
  GET_EMPLOYEE_LIBRARY_SUMMARY,
  GET_EMPLOYEE_BOOKS_SUMMARY,
  GET_EMPLOYEE_STORY_SUMMARY,
  GET_EMPLOYEE_BORROWED_BOOKS,
  GET_EMPLOYEE_NOT_RETURNED_BOOKS,
  GET_COMPREHENSIVE_REPORTS,
  GET_EMPLOYEE_APPLICATIONS,
  GET_LOGIN_HISTORY,
  GET_DB_HISTORY,
  ADD_EMPLOYEE_LOG,
  GET_DEPARTMENT_REPORT
  
} from "../constants/EmployeeArea";

const initState = {
  employeeListSearch: {
    size: 30,
    page: 1,
    first_name: "",
    civil_id: "",
    residency_start_date: "",
    residency_end_date: "",
    sort_order: "ASC",
    order_by: "ar_first_name",
  },
  // for Employee Listing
  employeeList: [],
  employeeListStatus: false,
  employeeListMsg: "",
  employeeListTotalItems: 0,
  employeeListCurrentPage: 0,

  // for Employee Profile Data
  employeeProfileData: [],
  employeeProfileDataStatus: false,

  // get employee Documents by ID
  employeeDocument: [],
  employeeDocumentTotalItems: 0,
  employeeDocumentCurrentPage: 0,
  employeeDocumentDetail: [],

  // for Employee Reports Listing
  employeeReports: [],
  employeeReportsStatus: false,
  employeeReportsMsg: "",
  employeeReportsTotalItems: 0,
  employeeReportsCurrentPage: 0,
  employeeReportsLists: [],
  employeeCountryList: [],
  employeeB_P_G_CountryList: [],
  activeEmployees: [],
  employeeTotals: [],
  allRequestTypes: [],
  requestOptions: [],
  currentEmployee: null,
  employeesWithLibrarySummary: [],
  employeesWithVacationsConfig: [],
  employeesWithVacationsList: [],
  employeesWithVacationsListForSet: [],
  employeeClasses: null,
  employeeLibrarySummary: [],
  employeeBooksSummary: [],
  employeeStorySummary: [],
  employeeBorrowedBooks: [],
  employeeNotReturnedBooks: [],
  comprehensiveReports:[],
  employeeApplications: {},
  loginHistory:[],
  dbHistory:[],
  employeeLogs:{},
  departemtReport:{}
};

const EmployeeArea = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case EMPLOYEE_SEARCH:
      return {
        ...state,
        employeeListSearch: payload,
      };
    case EMPLOYEE_COUNTRY_LIST:
      return {
        ...state,
        employeeCountryList: payload?.data || [],
      };
    case EMPLOYEE_BIRTH_PASSPORT_GRADUATION_COUNTRY_LIST:
      return {
        ...state,
        employeeB_P_G_CountryList: payload?.data || [],
      };
    case GET_EMPLOYEE_LIST:
      return {
        ...state,
        // schoolYear: payload
        employeeList: payload?.data?.result || [],
        employeeListStatus: payload?.status || false,
        employeeListMsg: payload?.msg || "Something Went Wrong",
        employeeListTotalItems: payload?.data?.totalItems || 0,
        employeeListCurrentPage: payload?.data?.currentPage || 0,
      };
    case GET_EMPLOYEE_BY_ID:
      return {
        ...state,
        employeeProfileData: payload?.data ? payload?.data : [],
        employeeProfileDataStatus: payload?.status || false,
      };
    case RESET_EMPLOYEE:
      return {
        ...state,
        employeeProfileData: [],
        employeeProfileDataStatus: false,
      };

    case GET_EMPLOYEE_DOCS:
      return {
        ...state,
        employeeDocument: payload?.data?.result || [],
        employeeDocumentTotalItems: payload?.data?.totalItems || 0,
        employeeDocumentCurrentPage: payload?.data?.currentPage || 0,
      };

    case GET_EMPLOYEE_DOCS_DETAILS:
      return {
        ...state,
        employeeDocumentDetail: payload?.data || [],
      };
    case GET_ALL_REQUEST_TYPES:
      return {
        ...state,
        allRequestTypes: payload.data || [],
      };

    case GET_ALL_EMPLOYEE_REQUESTS:
      return {
        ...state,
        employeeRequests: payload || [],
      };
    case SET_CURRENT_REQUEST:
      return {
        ...state,
        currentRequest: payload || null,
      };
    case GET_ALL_ACTIVE_EMPLOYEES:
      return {
        ...state,
        activeEmployees: payload || null,
      };
    case GET_EMPLOYEE_TOTALS:
      return {
        ...state,
        employeeTotals: payload || null,
      };
    case GET_ALL_REQUESTS_OPTIONS:
      return {
        ...state,
        requestOptions: payload || null,
      };
    case SET_CURRENT_EMPLOYEE:
      return {
        ...state,
        currentEmployee: payload || null,
      };
    case GET_EMPLOYEES_WITH_LIBRARY_SUMMARY:
      return {
        ...state,
        employeesWithLibrarySummary: payload || [],
      };
    case GET_ALL_EMPLOYEE_VACATION:
      return {
        ...state,
        employeesWithVacationsConfig: payload || [],
      };
    case GET_ALL_EMPLOYEE_VACATION_LIST:
      return {
        ...state,
        employeesWithVacationsList: payload || [],
      };
    case GET_ALL_EMPLOYEE_VACATION_LIST_FOR_SET:
      return {
        ...state,
        employeesWithVacationsListForSet: payload || [],
      };
    case GET_EMPLOYEE_CLASSES:
      return {
        ...state,
        employeeClasses: payload || null,
      };
    case GET_EMPLOYEE_LIBRARY_SUMMARY:
      return {
        ...state,
        employeeLibrarySummary: payload || [],
      };
    case GET_EMPLOYEE_BOOKS_SUMMARY:
      return {
        ...state,
        employeeBooksSummary: payload || [],
      };
    case GET_EMPLOYEE_STORY_SUMMARY:
      return {
        ...state,
        employeeStorySummary: payload || [],
      };
    case GET_EMPLOYEE_BORROWED_BOOKS:
      return {
        ...state,
        employeeBorrowedBooks: payload || [],
      };
    case GET_EMPLOYEE_NOT_RETURNED_BOOKS:
      return {
        ...state,
        employeeNotReturnedBooks: payload?.data || [],
      };
    case GET_COMPREHENSIVE_REPORTS:
      return {
        ...state,
        comprehensiveReports: payload?.data || []
      }
    case GET_EMPLOYEE_APPLICATIONS:
      return {
        ...state,
        employeeApplications: payload?.data || {}
      }
    case GET_LOGIN_HISTORY:
      return {
        ...state,
        loginHistory: payload?.data,
      }
    case GET_DB_HISTORY:
      return {
        ...state,
        dbHistory: payload?.data,
      }
    case ADD_EMPLOYEE_LOG:
      return {
        ...state,
        employeeLogs: payload?.data || {},
      }
    case GET_DEPARTMENT_REPORT:
      return {
        ...state,
        departemtReport: payload?.data || {},
      }
      
    default:
      return state;
  }
  // return state;
};

export default EmployeeArea;
